import React from 'react';
import { Formik } from 'formik';
import { graphql, useStaticQuery } from 'gatsby';

import {
    sectionBox,
    tableGrid,
    header,
    createBox,
    tableWrapper,
    loader,
    grid,
    active,
    finished,
    archived,
} from './client-project-listing.module.scss';
import { ISection } from '../../models/section.model';
import { TLocale } from '../../locale';
import { IQueryAllResult } from '../../models/query-all-result.model';
import { IPage } from '../../models/page.model';
import { IClientProject } from '../../models/client-project.model';
import { IClient } from '../../models/client.model';
import useTranslations from '../../hooks/use-translations';
import { useList } from '../../hooks/use-list';
import { useClient } from '../../hooks/use-client';
import { getDateFromUnixTimestamp } from '../../utils/get-date-from-unix-timestamp';
import { useClientPermission } from '../../hooks/use-client-permission';
import { usePagePathname } from '../../hooks/use-page-pathname';
import { useNoPermissionModal } from '../../hooks/use-no-permission-modal';
import { checkClientPermissions } from '../../utils/check-client-permissions';

import Section from '../hoc/section';
import Loader from '../atoms/loader';
import Table, { ITableProps } from '../organisms/table';
import Button from '../atoms/button';
import Tooltip from '../atoms/tooltip';
import IconFactory from '../hoc/icon-factory';
import HandleFormikChange from '../hoc/handle-formik-change';
import AlertInfo from '../molecules/alert-info';

interface IClientProjectListingProps {
    section: ISection;
}

interface IClientProjectListingQueryResult {
    allPage: IQueryAllResult<Pick<IPage, 'pathname' | 'locale' | 'type'>>;
}

const ClientProjectListing: React.FC<IClientProjectListingProps> = ({ section }) => {
    const { style, css, sectionId } = section;
    const t = useTranslations('ClientProjectListing');
    const client = useClient();
    const canProjects = useClientPermission(['can-projects']);
    const { addNoPermissionPlanModal, addNoPermissionRoleModal } = useNoPermissionModal();
    const { allPage } = useStaticQuery<IClientProjectListingQueryResult>(query);
    const projectDetailsPage = usePagePathname(allPage, 'client-project-preview');
    const projectCreatePage = usePagePathname(allPage, 'client-project-create');

    const {
        status,
        isInitialLoading,
        items,
        paginationPaths,
        filters,
        handleChange,
        handleSearch,
        values,
        sort,
        pagination,
        initialSearchValue,
        isEmpty,
    } = useList<IClientProject>({
        endpoint: `/client-projects`,
        token: client.data?.token.token,
        perPage: 6,
        apiKeywords: { search: 'search' },
    });

    return (
        <Section
            className={sectionBox}
            classes={{ container: grid }}
            style={style}
            sectionId={sectionId}
            css={css}
        >
            {isInitialLoading && <Loader className={loader} />}
            {!isInitialLoading && (
                <Formik onSubmit={() => {}} initialValues={values || {}} enableReinitialize={true}>
                    {(formik) => (
                        <div className={tableWrapper}>
                            <HandleFormikChange onChange={handleChange} />
                            <div className={header}>
                                <div className={createBox}>
                                    <Button
                                        {...(canProjects
                                            ? {
                                                  as: 'link',
                                                  to: projectCreatePage,
                                              }
                                            : {
                                                  type: 'button',
                                                  onClick: addNoPermissionPlanModal,
                                              })}
                                    >
                                        {t.newProject}
                                    </Button>
                                    <Tooltip>{t.hint}</Tooltip>
                                </div>
                            </div>
                            {isEmpty && <AlertInfo>{t.empty}</AlertInfo>}
                            {!isEmpty && (
                                <Table
                                    tableClassName={tableGrid}
                                    headerCells={getHeaderCells(t)}
                                    rows={getProjectRows(
                                        t,
                                        items,
                                        projectDetailsPage,
                                        projectCreatePage,
                                        client.data,
                                        addNoPermissionRoleModal,
                                        addNoPermissionPlanModal
                                    )}
                                    status={status}
                                    filters={filters}
                                    totalCount={pagination?.totalCount || 0}
                                    paginationPaths={paginationPaths}
                                    formik={formik}
                                    sort={sort}
                                    searchInputProps={{
                                        onSearch: handleSearch,
                                        placeholder: t.search,
                                        initialValue: initialSearchValue,
                                    }}
                                />
                            )}
                        </div>
                    )}
                </Formik>
            )}
        </Section>
    );
};

function getHeaderCells(t: TLocale['ClientProjectListing']): ITableProps['headerCells'] {
    return [
        { label: t.id },
        { label: t.name },
        { label: t.createdAt, field: 'createdAt' },
        { label: t.finishedAt, field: 'finishedAt' },
        { label: t.location },
        { label: t.offers, field: 'advertisementsCount' },
        { label: t.team },
        { label: t.owner },
        { label: t.status.label },
        { label: '' },
    ];
}

function getProjectRows(
    t: TLocale['ClientProjectListing'],
    projects: IClientProject[],
    projectDetailsPage: string,
    projectCreatePage: string,
    client: IClient | undefined | null,
    onNoPermissionRole: () => void,
    onNoPermissionPlan: () => void
): ITableProps['rows'] {
    const canProjects = checkClientPermissions(client, ['can-projects']);
    return projects.map((project) => {
        return [
            {
                type: 'data',
                label: t.id,
                value: project.id,
                valueStyle: 'bold',
            },
            {
                type: 'data',
                label: t.name,
                value: project.name,
            },
            {
                type: 'data',
                label: t.createdAt,
                value: getDateFromUnixTimestamp(project.createdAt, 'dash'),
            },
            {
                type: 'data',
                label: t.finishedAt,
                value: getDateFromUnixTimestamp(project.closedAt, 'dash'),
            },
            {
                type: 'data',
                label: t.location,
                value: project.location,
            },
            {
                type: 'data',
                label: t.offers,
                value: project.advertisementsCount,
            },
            {
                type: 'data',
                label: t.team,
                value: project.teams.map((team) => team.name).join(', '),
            },
            {
                type: 'data',
                label: t.owner,
                value: project.owner,
            },
            {
                type: 'data',
                label: t.status.label,
                className:
                    project.status === 1 ? active : project.status === 2 ? finished : archived,
                value: getStatusTranslation(project.status, t),
            },
            {
                type: 'action',
                actions: [
                    project.status === 3
                        ? null
                        : {
                              ...(!canProjects || !project.canEdit
                                  ? {
                                        type: 'button',
                                        onClick: !canProjects
                                            ? onNoPermissionPlan
                                            : onNoPermissionRole,
                                    }
                                  : {
                                        as: 'link',
                                        to: `${projectCreatePage}?id=${project.id}`,
                                    }),
                              stylePreset: 'secondary',
                              size: 'medium',
                              shape: 'circle',
                              children: <IconFactory icon="edit" />,
                          },
                    {
                        as: 'link',
                        stylePreset: 'secondary',
                        size: 'medium',
                        children: t.see,
                        to: `${projectDetailsPage}?id=${project.id}`,
                    },
                ],
            },
        ];
    });
}

const getStatusTranslation = (status: number, t: TLocale['ClientProjectListing']) => {
    switch (status) {
        case 1:
            return t.status.active;
        case 2:
            return t.status.finished;
        case 3:
            return t.status.archived;
    }
};

export const query = graphql`
    query {
        allPage(filter: { type: { in: ["client-project-preview", "client-project-create"] } }) {
            edges {
                node {
                    pathname
                    type
                    locale
                }
            }
        }
    }
`;

export default ClientProjectListing;
