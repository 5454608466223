import { useModal } from './use-modal';

export const useNoPermissionModal = () => {
    const { addModal } = useModal();
    return {
        addNoPermissionRoleModal: () =>
            addModal({ modalKey: 'no-permission-modal', modalProps: { reason: 'role' } }),
        addNoPermissionPlanModal: () =>
            addModal({ modalKey: 'no-permission-modal', modalProps: { reason: 'plan' } }),
    };
};
