// extracted by mini-css-extract-plugin
export var active = "client-project-listing-module--active--29c77";
export var archived = "client-project-listing-module--archived--b4a5f";
export var backgroundAnimation = "client-project-listing-module--backgroundAnimation--dbb7d";
export var createBox = "client-project-listing-module--create-box--be326";
export var finished = "client-project-listing-module--finished--be1a2";
export var grid = "client-project-listing-module--grid--0243d";
export var header = "client-project-listing-module--header--4819c";
export var loader = "client-project-listing-module--loader--bcec5";
export var sectionBox = "client-project-listing-module--section-box--aedea";
export var tableGrid = "client-project-listing-module--table-grid--60689";
export var tableWrapper = "client-project-listing-module--table-wrapper--0f09a";